import React, {useContext, useEffect, useState} from "react";
import {Fade} from "react-reveal";
//import emoji from "react-easy-emoji";
import "./Greeting.scss";
//import landingPerson from "../../assets/lottie/landingPerson";
//import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import gifHi from '../../assets/images/hi.gif'; 
import gifdev from '../../assets/images/hello.gif'; 


import { greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    // Split the text into an array of characters
    const textArray = greeting.title.split('');

    // Initialize the index and timerId
    let index = 0;
    let timerId;

    // Function to update the typedText state
    const typeEffect = () => {
      setTypedText((prevText) => prevText + textArray[index]);
      index++;

      // Check if all characters are typed
      if (index < textArray.length) {
        timerId = setTimeout(typeEffect, 100); // Adjust the typing speed as needed
      }
    };

    // Start the typing effect
    typeEffect();

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
  }, []);
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
    
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className="dark-mode greeting-text" 
              >
                {" "}
                
                {typedText} <img width="10%" src={gifHi} alt="Hello" />               <span className="wave-emoji">          
</span>
{" "}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {greeting.subTitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
          <img src={gifdev} alt="Hello" />

          </div>
        </div>
      </div>
    </Fade>
  );
}
