//import React, {useState, useContext} from "react";
//import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.scss";

const ToggleSwitch = () => {
  //const {isDark} = useContext(StyleContext);
 // const [isChecked, setChecked] = useState(isDark);
  //const styleContext = useContext(StyleContext);

  return (
    <label className="switch">
     
    </label>
  );
};
export default ToggleSwitch;
